import React from "react";

const htmlString = `
<section class="py-5 my-5"
    style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
    <div class="col-area" style=" margin-top: 5%">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="col-content">
                        <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Medical<br> <span
                                class="us-text" style="color: #059FE7;font-weight: 1000;"> Coding</span></h4>
                        <p class="text-p mt-3" style="padding-left:45px;">Accurate and efficient medical coding is the
                            backbone of successful medical billing. At RDS Precision, we provide comprehensive medical
                            coding services that ensure precision, compliance, and maximum reimbursement for your
                            practice. Our team of certified coding professionals is dedicated to translating your
                            medical records into standardized codes accurately and swiftly.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-center img-col"><img src="assets/img/Medical-Coding .webp" class="w-75" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="container text-center">
    <h1 style="color:black;">Why Choose Our <span style="color: #059FE7;font-weight: bold;">Medical Coding
            Services?</span></h1>
</div>

<section class="bass" style=" margin-top: -20px;">
    <div class="ar-t py-5">
        <div class="container">
            <h2 style="font-weight: bold;">Certified Expertise:</h2>
            <p>
                Our coders are certified by the American Academy of
                Professional Coders (AAPC) and the American Health
                Information Management Association (AHIMA). They
                possess extensive experience across various medical
                specialties.
                Accuracy and Compliance: We adhere to the latest
                coding guidelines and regulations, including ICD-10-CM/PCS,
                CPT, and HCPCS codes. Our meticulous approach ensures your
                practice remains compliant and reduces the risk of audits.

            </p>
            <h2 style="font-weight: bold;">Accuracy and Compliance:</h2>
            <p> We adhere to the latest coding guidelines and regulations,
                including ICD-10-CM/PCS, CPT, and HCPCS codes. Our meticulous
                approach ensures your practice remains compliant and reduces
                the risk of audits.
            </p>


            <h2 style="font-weight: bold;"> Specialized Knowledge:</h2>
            <p>
                Our team has specialized expertise in coding for a wide range of medical fields such as cardiology,
                orthopedics, oncology, and more. This ensures precise coding tailored to your specialty.
            </p>

            <h2 style="font-weight: bold;">Payment Posting:</h2>
            <p>
                Upon receipt of payments from insurance companies, our payment posting team ensures accurate posting of
                payments against the corresponding claims.
                Our advanced reconciliation processes help identify discrepancies and ensure that payments are posted
                correctly, minimizing revenue leakage.
            </p>

            <h2 style="font-weight: bold;">Reimbursement and Reporting:</h2>
            <p>
                Finally, once claims are processed and reimbursed, our system generates detailed reports to track key
                performance metrics such as reimbursement rates, denial rates, and days in accounts receivable.
                These reports provide valuable insights into the financial health of the practice and help identify
                areas for improvement in billing and revenue cycle management.
            </p>

        </div>
    </div>
</section>


<div class="container text-center">
    <h1 style="color:black;">Why Choose Our <span style="color: #059FE7;font-weight: bold;">Medical Coding
            Services?</span></h1>
</div>

<section class="bass" style=" margin-top: -20px;">
    <div class="ar-t py-5">
        <div class="container">
            <h2 style="font-weight: bold;">Certified Expertise:</h2>
            <p>
                Our coders are certified by the American Academy of
                Professional Coders (AAPC) and the American Health
                Information Management Association (AHIMA). They
                possess extensive experience across various medical
                specialties.
                Accuracy and Compliance: We adhere to the latest
                coding guidelines and regulations, including ICD-10-CM/PCS,
                CPT, and HCPCS codes. Our meticulous approach ensures your
                practice remains compliant and reduces the risk of audits.

            </p>
            <h2 style="font-weight: bold;">Accuracy and Compliance:</h2>
            <p> We adhere to the latest coding guidelines and regulations,
                including ICD-10-CM/PCS, CPT, and HCPCS codes. Our meticulous
                approach ensures your practice remains compliant and reduces
                the risk of audits.
            </p>


            <h2 style="font-weight: bold;"> Specialized Knowledge:</h2>
            <p>
                Our team has specialized expertise in coding for a wide range of medical fields such as cardiology,
                orthopedics, oncology, and more. This ensures precise coding tailored to your specialty.
            </p>

            <h2 style="font-weight: bold;">Payment Posting:</h2>
            <p>
                Upon receipt of payments from insurance companies, our payment posting team ensures accurate posting of
                payments against the corresponding claims.
                Our advanced reconciliation processes help identify discrepancies and ensure that payments are posted
                correctly, minimizing revenue leakage.
            </p>

            <h2 style="font-weight: bold;">Reimbursement and Reporting:</h2>
            <p>
                Finally, once claims are processed and reimbursed, our system generates detailed reports to track key
                performance metrics such as reimbursement rates, denial rates, and days in accounts receivable.
                These reports provide valuable insights into the financial health of the practice and help identify
                areas for improvement in billing and revenue cycle management.
            </p>

        </div>
    </div>
</section>


<div class="container text-center">
    <h1 style="color:black;">Our Medical <span style="color: #059FE7;font-weight: bold;">Coding Services</span></h1>
</div>

<section class="bass" style=" margin-top: -20px;">
    <div class="ar-t py-5">
        <div class="container">
            <h2 style="font-weight: bold;"> ICD-10-CM/PCS Coding:</h2>
            <p>
                Comprehensive coding for diagnoses and inpatient procedures.
                Regular updates to incorporate the latest changes in ICD-10 codes.

            </p>
            <h2 style="font-weight: bold;">CPT and HCPCS Coding:</h2>
            <p> Accurate coding for outpatient services, medical procedures, and supplies.
                Ensuring compliance with the latest CPT and HCPCS code sets.
            </p>


            <h2 style="font-weight: bold;"> Auditing and Compliance:</h2>
            <p>
                Regular internal audits to ensure coding accuracy and adherence to regulations.
                Preparation for external audits and assistance with audit responses.
            </p>
        </div>
    </div>
</section>

<div class="container text-center">
    <h1 style="color:black;">Benefits of Our <span style="color: #059FE7;font-weight: bold;">Medical Coding
            Services</span></h1>
</div>

<section class="bass" style=" margin-top: -20px;">
    <div class="ar-t py-5">
        <div class="container">
            <h2 style="font-weight: bold;">Enhanced Reimbursement:</h2>
            <p>
                Accurate coding ensures that your claims are processed efficiently, leading to timely and appropriate
                reimbursements.

            </p>
            <h2 style="font-weight: bold;">Accuracy and Compliance:</h2>
            <p> We adhere to the latest coding guidelines and regulations,
                including ICD-10-CM/PCS, CPT, and HCPCS codes. Our meticulous
                approach ensures your practice remains compliant and reduces
                the risk of audits.
            </p>


            <h2 style="font-weight: bold;"> Improved Efficiency: </h2>
            <p>
                Our services streamline your billing process, allowing your staff to focus on patient care
            </p>

            <h2 style="font-weight: bold;">Compliance Assurance: </h2>
            <p>
                We keep your practice compliant with the ever-changing regulations, safeguarding you from penalties and
                audits.
            </p>

            <h2 style="font-weight: bold;">Reimbursement and Reporting:</h2>
            <p>
                Finally, once claims are processed and reimbursed, our system generates detailed reports to track key
                performance metrics such as reimbursement rates, denial rates, and days in accounts receivable.
                These reports provide valuable insights into the financial health of the practice and help identify
                areas for improvement in billing and revenue cycle management.
            </p>

        </div>
    </div>
</section>

<div class="container text-center">
    <h1 style="color:black;">Our<span style="color: #059FE7;font-weight: bold;"> Process </span></h1>
</div>

<section class="bass" style=" margin-top: -20px;">
    <div class="ar-t py-5">
        <div class="container">
            <h2 style="font-weight: bold;">Data Collection:</h2>
            <p>
                We gather all necessary medical records and documentation from your practice.

            </p>
            <h2 style="font-weight: bold;">Coding Assignment:</h2>
            <p> Our certified coders assign the appropriate ICD-10, CPT, and HCPCS codes based on the documentation
                provided.
                Quality Check: Each coded record undergoes a rigorous quality check to ensure accuracy and compliance.
            </p>


            <h2 style="font-weight: bold;"> Submission:</h2>
            <p>
                Once verified, the coded records are submitted for billing.
                Feedback and Reporting: We provide detailed reports and feedback to help your practice improve
                documentation and coding practices.
            </p>

            <h2 style="font-weight: bold;"> Quality Check: </h2>
            <p>
                Each coded record undergoes a rigorous quality check to ensure accuracy and compliance.
            </p>

            <h2 style="font-weight: bold;">Feedback and Reporting:</h2>
            <p>
                We provide detailed reports and feedback to help your practice improve documentation and coding
                practices.
            </p>

        </div>
    </div>
</section>

<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
    <div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
        <h2 style="font-weight:bolder;">Ready to Talk?</h2>
        <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
            <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
        </div>
        <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
            <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
        </div>
        <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
            <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
        </div>
        <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
            <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
        </div>
    </div>
</div>
`;
function Digitalmarketing() {
  console.log("Digitalmarketing called");

  const handleClick = (path) => {
    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Digitalmarketing;
