import React, { useState } from 'react';

const LoginPagea = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [sessionToken, setSessionToken] = useState('');
    const [step, setStep] = useState('initial');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://med-b.ealamgroup.pk/init_login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setSessionToken(data.session_token);
                setStep('verify');
                setError(''); // Clear previous errors
            } else {
                setError(data.message || 'An error occurred while logging in.');
            }
        } catch (err) {
            setError('An error occurred while logging in.');
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://med-b.ealamgroup.pk/complete_login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ session_token: sessionToken, totp_code: totpCode })
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess('Login successful!');
                setError('');
            } else {
                setError(data.message);
            }
        } catch (err) {
            setError('An error occurred while verifying TOTP code.');
        }
    };

    return (
        <div>
            {step === 'initial' ? (
                <form onSubmit={handleLogin}>
                    <h2>Login</h2>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Submit</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </form>
            ) : step === 'verify' ? (
                <form onSubmit={handleVerify}>
                    <h2>Verify TOTP Code</h2>
                    <div>
                        <label>TOTP Code:</label>
                        <input
                            type="text"
                            value={totpCode}
                            onChange={(e) => setTotpCode(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Verify</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                </form>
            ) : null}
        </div>
    );
};

export default LoginPagea;
