import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from './config';
import logo2 from "../assets/img/log0.png";
import "../assets/css/login.css";

const Register = () =>
{
  const [ formData, setFormData ] = useState( {
    email: '',
    phone: '',
    username: '',
    password: '',
    confirmPassword: '',
  } );
  const [ error, setError ] = useState( null );
  const history = useNavigate();
  const RegisterEndpoint = `${ BASE_URL }register`;

  const makeApiRequest = async ( url, method, data ) =>
  {
    try
    {
      const response = await fetch( url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( data ),
      } );

      if ( response.ok )
      {
        const responseData = await response.json();
        return responseData;
      } else
      {
        throw new Error( 'Request failed' );
      }
    } catch ( error )
    {
      throw error;
    }
  };

  const handleRegistration = async () =>
  {
    try
    {
      const { email, phone, username, password, confirmPassword } = formData;

      if ( !email.endsWith( '@gmail.com' ) )
      {
        setError( 'Email must end with "@gmail.com"' );
        return;
      }

      if ( password !== confirmPassword )
      {
        setError( 'Password and confirm password do not match' );
        return;
      }

      const registrationData = { email, phone, username, password };

      const response = await makeApiRequest( RegisterEndpoint, 'POST', registrationData );

      if ( response && response.message === 'User registered successfully' )
      {
        console.log( 'User registered successfully' );
        history( '/Login' );
      } else
      {
        setError( 'Registration failed. Please check your data and try again.' );
      }
    } catch ( error )
    {
      console.error( 'An error occurred:', error );
      setError( 'User already exists' );
    }
  };

  const handleChange = ( e ) =>
  {
    const { name, value } = e.target;
    setFormData( { ...formData, [ name ]: value } );
    setError( null ); // Clear the error when the input changes.
  };

  return (
    <section>
       <div className="background">
      <div className="diamond diamond-1"></div>
    <div
      className="m-auto w-lg-75 w-xl-50"
      style={{
        padding: '47px',
        margin: '165.9px 336.698px',
        marginTop: '100.3px',
        paddingTop: '50px',
        height: '750px',
        width: '400px',
        borderWidth: '0px',
        borderColor: 'gray',
      }}
    >
      <div className="row justify-content-center" style={{ marginBottom: '37px' }}>
        <div className="col-auto align-items-center align-content-center">
          <img
            src={logo2}
            alt="Logo"
            data-bss-hover-animate="rubberBand"
            style={{ height: '12vh', color: '#059FE7' }}
          />
        </div>
      </div>
      <p style={{ color: '#059FE7', fontSize: '36px', fontWeight: 'bold', width: '296.7px', marginLeft: '0px' }}>
      Create Account
      </p>
      <form>
        <div class="form-group mb-3">
        <label className="form-label text-secondary" 
        style={{
          color: 'rgb(169, 169, 169) !important',
          fontWeight: 'bold',
          fontSize: '18px',
        }}
        htmlFor="username">Username:</label>
        <input
          className="form-control"
          id="username"
          name="username"
          type="text"
          required
          value={ formData.username } // Fixed incorrect property name
          onChange={ handleChange }
        />

          <label className="form-label text-secondary" 
        style={{
          color: 'rgb(169, 169, 169) !important',
          fontWeight: 'bold',
          fontSize: '18px',
        }} htmlFor="email">Email ID:</label>
        <input
          className="form-control"
          id="email"
          name="email"
          type="email"
          required
          value={ formData.email }
          onChange={ handleChange }
        />

            <label className="form-label text-secondary" 
        style={{
          color: 'rgb(169, 169, 169) !important',
          fontWeight: 'bold',
          fontSize: '18px',
        }} htmlFor="phone">Phone:</label>
        <input
          className="form-control"
          id="phone"
          name="phone"
          type="phone"
          required
          value={ formData.phone }
          onChange={ handleChange }
        />

          <label className="form-label text-secondary" 
        style={{
          color: 'rgb(169, 169, 169) !important',
          fontWeight: 'bold',
          fontSize: '18px',
        }} htmlFor="password">Password:</label>
        <input
          className="form-control"
          id="password"
          name="password"
          type="password"
          required
          value={ formData.password }
          onChange={ handleChange }
        />

         <label className="form-label text-secondary" 
        style={{
          color: 'rgb(169, 169, 169) !important',
          fontWeight: 'bold',
          fontSize: '18px',
        }} htmlFor="confirmPassword">Re-Password:</label>
        <input
          className="form-control"
          id="confirmPassword" // Changed to confirmPassword
          name="confirmPassword"
          type="password"
          required
          value={ formData.confirmPassword } // Fixed incorrect property name
          onChange={ handleChange }
        />
      </div>
      </form>
      { error && <p className="error">{ error }</p> }
      <div className="row justify-content-end">
<div
  className="col-auto col-lg-12 d-flex flex-column justify-content-center align-items-start align-content-center align-self-center"
  style={{ /* text-align: 'right' */ }}
>
  <button
    data-bss-hover-animate="rubberBand"
    className="see-all-button"
    style={{
      color: '#059FE7',
      borderColor: '#059FE7',
      paddingLeft: '20px',
      marginLeft: '0px',
      marginRight: '60px',
      marginTop: '20px',
    }}
    onClick={handleRegistration}
  >
    Submit
  </button>
</div>
</div>
<div className="row justify-content-end">
<div
  className="col-auto col-lg-12 d-flex flex-column justify-content-center align-items-start align-content-center align-self-center"
  style={{ /* text-align: 'right' */ }}
>
  <Link
    className="link-danger text-info small"
    style={{
      marginBottom: '0px',
      marginTop: '22px',
      paddingTop: '0px',
      fontWeight: 'bold',
    }}
    to="/Login"
  >
    Already have an account?
  </Link>
</div>
</div>
    </div>
    <div class="diamond diamond-2"></div>
    </div>
    </section>
  );
};

export default Register;


