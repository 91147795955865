import React from 'react';
import OB_GN from '../assets/images/common/OB_GN.jpg'
const htmlString = `
<section class="denter" style="width: 100%; height: 30vh; background-image: url('${OB_GN}'); background-size: cover; background-position: center;">
<div class="container">
      <h1 style=" margin-top: 120px; color: #059FE7; font-family: 'Noto Sans Canadian Aboriginal', sans-serif; padding: 3px; font-size: 42px; font-weight: bold;"> OB/GYN : </h1>
  </div>
</section>
<section class="bass" style=" margin-top: 50px;">
<div class="ar-t py-5">
<div class="container">
<h2 style="font-weight: bold;">Advanced-based RCM services for OB/ GYN practices.</h2>
<p>OB/GYN professionals require a partner that understands their unique needs, offering comprehensive support that spans from advanced reporting and analytics to efficient billing and precisely timed appointment scheduling._ _ is dedicated to meeting these needs with a level of sophistication that sets a new standard in the field.
</p>
<h2 style="font-weight: bold;">Consider the highlights of our service:</h2>
<p>We boast a remarkable 99% first-pass claim acceptance rate, ensuring that nearly all claims are processed smoothly right from the outset.
    <br>
    Our success rate in resolving denied claims at the first attempt is also impressively close to 99%, highlighting our expertise in navigating the complexities of claim resolution.
    <br>
    We handle both in-network and out-of-network claims with ease, adapting to your specific billing requirements.
    <br>
    Our approach significantly enhances workflow and practice management, tailored specifically to the rhythms and demands of OB/GYN practices.
    <br>
    We offer detailed OB/GYN reports and analyses, providing deep insights into your practice’s operations and opportunities for growth.
    <br>
    Our scheduling system is designed with OB/GYN practices in mind, ensuring that your workflow is as efficient as possible.
    <br>
    Our practice management system is crafted to cater specifically to the needs of gynecologists and nurse practitioners in women's health, offering 24/7 access from any location with internet connectivity, whether you're at home, in the hospital, or elsewhere.
   </p>
   <h2 style="font-weight: bold;"> Having an integrated billing system can truly enhance your professional image and the quality of services you offer</h2>
    <h4 style="font-weight: bold;">
    By partnering with OB/GYN billing specialists, you can elevate your practice in several key areas:</h4>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Enhancing regulatory compliance.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Boosting net revenue.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Streamlining RCM processes.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Utilizing optimization tools for revenue cycles.</p>

<p>
Our specialists are dedicated to increasing your collection rates and minimizing workflow challenges. The beauty of working with us lies in our ability to address all your concerns with effective solutions.
</p>
</div>
</div>
</section>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;

function GYN() {
    console.log("Oncology called");
    const handleClick = (path) => {
  
      console.log(`Navigating to: ${path}`);
  
      window.location.href = path;
    };
  
  
    window.handleClick = handleClick;
  
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }
export default GYN;