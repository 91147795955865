import React from "react";
import "./special.css";

const htmlString = `
<section class="d-flex align-items-center hero0" id="hero"
style="background:white;padding-top:25%;margin-top:-12%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
<div class="container">
    <div class="row">
        <div class="col-6 col-md-6" style="margin-top: 35px;">
            <h1 class="ban-text" style="font-size:45px;font-weight:500;"> RDS Precision
 <span class="leave-h1"
                    style="color: #059FE7;"> specialities </span></h1><br>
            <p class="text-p"> Integrates the operational and clinical facets of healthcare specialties through the automated management of administrative functions. Our team of specialized medical professionals oversees the entirety of your medical care journey.
            </p>
        </div>
        <div class="col-6 col-md-5"><img src="assets/img/shriq.jpg" alt="" style="height: 300px; width: 490px;"></div>
    </div>
</div>
</section>

<div class="container text-center" style="margin-top:15%;">
<h4 class="text-center text-darker mb-5" style="color:black;font-weight:bold;">Our <span
    class="us-text" style="color: #059FE7;">specialities</span></h4>
<p style="padding:1%;color:rgb(85, 83, 83);font-size:larger;">Your specialty requires bespoke solutions for medical billing, practice management, and electronic health records (EHR).<br> We not only meet these needs but also enhance your practice’s performance—financial, clinical, and patient-focused—through our comprehensive, cloud-based platform.</P>
<div class="row">
    <div class="col">
    <a onclick="handleClick('./GYN')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
            <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fas fa-user-md"></i></div>
            <h4 style="font-weight:bold;">GYN</h4>
        </div>
        </a>
    </div>
    <div class="col">
    <a onclick="handleClick('./Cardiology')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fas fa-heartbeat"></i></div>
        <h4 style="font-weight:bold;">Cardiology</h4>
        </div>
        </a>
    </div>
    <div class="col">
    <a onclick="handleClick('./Dermatology')">
    <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
    <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-head-side-mask"></i></div>
    <h4 style="font-weight:bold;">Dermatology</h4>
    </div>
    </a>
    </div>
    <div class="col">
        <a onclick="handleClick('./Psychiatry')">
           <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
           <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-square-plus"></i></div>
           <h4 style="font-weight:bold;">Psychiatry</h4>
           </div>
           </a>
        </div>
</div>
<br>
<div class="row">

  <div class="col">
  <a onclick="handleClick('./Endocrinology')">
      <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
      <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-brain""></i></div>
      <h4 style="font-weight:bold;">Endocrinology</h4>
      </div>
      </a>
  </div>
  <div class="col">
  <a onclick="handleClick('./Pulmonology')">
      <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
      <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-viruses"></i></div>
      <h4 style="font-weight:bold;">Pulmonology</h4>
      </div>
      </a>
  </div>
  <div class="col">
  <a onclick="handleClick('./Therapy')">
      <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
      <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-lungs"></i></div>
      <h4 style="font-weight:bold;">Speech Therapy</h4>
      </div>
      </a>
  </div>
  <div class="col">
    <a onclick="handleClick('./Neurology')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-vials"></i></div>
        <h4 style="font-weight:bold;">Neurology</h4>
        </div>
        </a>
    </div>
</div>
<br>
<div class="row">

<div class="col">
<a onclick="handleClick('./Orthopedic')">
    <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
    <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-bone"></i></div>
    <h4 style="font-weight:bold;">Orthopedic</h4>
    </div>
    </a>
</div>
<div class="col">
<a onclick="handleClick('./Pediatrics')">
    <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
    <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fas fa-baby"></i></div>
    <h4 style="font-weight:bold;">Pediatrics</h4>
    </div>
    </a>
</div>
<div class="col">
<a onclick="handleClick('./Medicine')">
    <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
    <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fas fa-pills"></i></div>
    <h4 style="font-weight:bold;">Family Medicine</h4>
    </div>
    </a>
</div>
<div class="col">
    <a onclick="handleClick('./Anesthesiology')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-regular fa-eye"></i></div>
        <h4 style="font-weight:bold;">Anesthesiology</h4>
        </div>
        </a>
    </div>
</div>
<br>
<div class="row">

    <div class="col">
    <a onclick="handleClick('./Orthopedic')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-person-cane"></i></div>
        <h4 style="font-weight:bold;">Chiropractic</h4>
        </div>
        </a>
    </div>
    <div class="col">
    <a onclick="handleClick('./Dental')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-cannabis"></i></div>
        <h4 style="font-weight:bold;">Dental</h4>
        </div>
        </a>
    </div>
    <div class="col">
    <a onclick="handleClick('./DMC')">
        <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
        <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fas fa-pills"></i></div>
        <h4 style="font-weight:bold;">DMC</h4>
        </div>
        </a>
    </div>
    <div class="col">
        <a onclick="handleClick('./FQHC')">
            <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
            <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-bacteria"></i></div>
            <h4 style="font-weight:bold;">FQHC</h4>
            </div>
            </a>
        </div>
    </div>
    <br>
    <div class="row">

        <div class="col">
        <a onclick="handleClick('./Gastroenterology')">
            <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
            <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-teeth-open"></i></div>
            <h4 style="font-weight:bold;">Gastroenterology</h4>
            </div>
            </a>
        </div>
        <div class="col">
        <a onclick="handleClick('./Surgery')">
            <div class="service-card-border" style="display: flex; flex-direction: column; height:250px; border-radius: 25px; box-shadow: 10px 10px 10px #c5c0c0; ">
            <div class="icon" style="padding: 10px; font-size: 45px;"><i class="fa-solid fa-staff-snake"></i></div>
            <h4 style="font-weight:bold;">General-Surgery</h4>
            </div>
            </a>
        </div>
        </div>
</div>
<br>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;

function Specialities() {
  console.log("Specialities called");
  const handleClick = (path) => {
    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Specialities;
