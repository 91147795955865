import React, { useState } from "react";
import "../assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";
import logo2 from "../assets/img/log0.png";
import { BASE_URL } from "./config";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const history = useNavigate();
  const loginEndpoint = `${BASE_URL}login`;

  const handleLogin = async () => {
    try {
      const response = await fetch(loginEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.status === "success") {
        setError("Login Successful. Welcome to HomePage...");
        localStorage.setItem("token", data.token);

        setTimeout(() => {
          history("/NavBar2");
        });
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <section
    >
      <div className="background">
      <div className="diamond diamond-1"></div>
      <div
        className="m-auto w-lg-75 w-xl-50"
        style={{
          padding: '47px',
          margin: '165.9px 336.698px',
          marginTop: '100.3px',
          paddingTop: '50px',
          height: '750.3px',
          width: '400px',
          borderWidth: '0px',
          borderColor: 'gray',
        }}
      >
        <div className="row justify-content-center" style={{ marginBottom: '37px' }}>
          <div className="col-auto align-items-center align-content-center">
            <img
              src={logo2}
              alt="Logo"
              data-bss-hover-animate="rubberBand"
              style={{ height: '12vh', color: '#059FE7' }}
            />
          </div>
        </div>
        <p style={{ color: '#059FE7', fontSize: '36px', fontWeight: 'bold', width: '296.7px', marginLeft: '65px' }}>
          Login
        </p>
        <form>
          <div className="form-group mb-3">
            <label className="form-label text-secondary"
              style={{
                color: 'rgb(169, 169, 169) !important',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              htmlFor="username">
              Username:
            </label>
            <input
              className="form-control"
              type="text"
              id="username"
              name="username"
              required
              value={formData.username}
              onChange={handleChange}
            />

            <label className="form-label text-secondary"
              style={{
                color: 'rgb(169, 169, 169) !important',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              htmlFor="password">
              Password:
            </label>
            <input
              className="form-control"
              type="password"
              id="password"
              name="password"
              required
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          </form>
          <div className="button">
            <button
              data-bss-hover-animate="rubberBand"
              className="see-all-button"
              style={{
                color: '#059FE7',
                borderColor: '#059FE7',
                paddingLeft: '20px',
                marginLeft: '0px',
                marginRight: '60px',
                marginTop: '20px',
              }}
              onClick={handleLogin}
            >
              Login
            </button>
            <Link to="/Reset.html" className="forgot-password-link">
              Forgot Password
            </Link>
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="row justify-content-end">
            <div
              className="col-auto col-lg-12 d-flex flex-column justify-content-center align-items-start align-content-center align-self-center"
              style={{ /* text-align: 'right' */ }}
            >
              <p custom-type="plogin">
                Don't have an account?{' '}
                <Link
                  className="link-danger text-info small"
                  style={{
                    marginBottom: '0px',
                    marginTop: '22px',
                    paddingTop: '0px',
                    fontWeight: 'bold',
                  }}
                  to="/Register"
                >
                  Create new
                </Link>
              </p>
            </div>
          </div>
  
       </div >
       <div class="diamond diamond-2"></div>
       </div>
      </section >

  );
};

export default Login;

