import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import LoginPagea from './LoginPagea';

// import Profile from './components/admin/admin_files/Profile';
// import NavBar2 from './components/admin/admin_files/some';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    <LoginPagea/>
    {/* <NavBar2/> */}
    {/* <Profile/> */}
    </BrowserRouter> 
  </React.StrictMode>,
  document.getElementById('root')
);