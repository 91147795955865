import React from 'react';
const htmlString = `
<section class="d-flex align-items-center" id="herooo">
<div class="container"></div>
</section>
<section class="pb-100">
<div class="cont-fm px-5">
    <div class="container">
        <form class="contact-pp" style="margin-top:-70px;">
            <div class="contact-card">
                <h3 class="heading-2" style="text-align:center;">Leave a message</h3>
                <div class="row">
                    <div class="col-md-6 mb-4"><input class="form-control form-control tp-form" type="text"
                            name="name" placeholder="Full name" required=""></div>
                    <div class="col-md-6 mb-4"><input class="form-control form-control tp-form" type="email"
                            name="email" placeholder="Email" required=""></div>
                    <div class="col-md-6 mb-4"><input class="form-control form-control tp-form" type="text"
                            name="phone" placeholder="Phone number" required=""></div>
                    <div class="col-md-6 mb-4"><input class="form-control form-control tp-form" type="text"
                            name="subject" placeholder="Subject"></div>
                    <div class="col-md-12"><textarea class="form-control form-control tp-form" cols="30"
                            name="message" placeholder="Message" required="" rows="10"></textarea></div>
                </div>
                <div class="btn-top"><button data-bss-hover-animate="rubberBand" class="see-all-button"
                        style="color: #059FE7;border-color: #059FE7;padding-left: 20px;margin-left: 0px;margin-right: 170px;margin-top: 20px;">Login</button>
                </div>
            </div>
        </form>
    </div>
</div>
</section>
`;

function HIPAA() {
  console.log("HIPAA called");
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default HIPAA;