import React from 'react';
const htmlString = `
<footer id="footer" style="background: var(--bs-emphasis-color);">
<div class="footer-top" style="background: rgb(0,0,0);">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-2 footer-contact" style="display: flex; flex-direction: row; align-items: center;">
      <img src="assets/img/logo-01.svg" style="height:8vh;">
      <img src="assets/img/text.svg" style="height:18px;">
      </div>
      <div class="col-md-6 col-lg-2 footer-links">
        <h4>Solutions</h4>
        <ul>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a  onclick="handleClick('./Revenue')">Revenue Cycle Management</a></li>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a  onclick="handleClick('./Digitalmarketing')">Healthcare Digital Marketing</a></li>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a  onclick="handleClick('./Analytics')">Healthcare Analytics</a></li>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a  onclick="handleClick('./Experience')">Patient Experience Management</a></li>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a  onclick="handleClick('./PracticeManagement')">Practice Management</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3 footer-links">
        <h4>Legal notice</h4>
        <ul>
          <li><i class="bx bx-chevron-right" style="color: #059FE7;"></i><a onclick="handleClick('./Privacy')">Privacy Policy</a></li>
          <li><i class="bx bx-chevron-right"></i><a onclick="handleClick('./Descrimation')">Non Descrimation</a></li>
          <li><i class="bx bx-chevron-right"></i><a onclick="handleClick('./Contact')">Contact</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3 footer-links">
        <h4>Follow Us</h4>
        <div class="text-center social-links text-md-right pt-3 pt-md-0" style="row-gap: 10px;display: flex;flex-direction: column-reverse; flex-direction: row;
        justify-content: space-between;"><br><a class="facebook"
            style="background: #059FE7;"><i class="bx bxl-facebook"></i></a>
            <a class="instagram"
            style="background: #059FE7;"><i class="bx bxl-instagram"></i></a>
            <a class="linkedin"
            style="background: #059FE7;"><i class="bx bxl-linkedin"></i></a></div>
      </div>
    </div>
  </div>
</div>
</footer>
`;

function Footer() {
  console.log('Footer called');

  const handleClick = (path) => {

    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Footer;