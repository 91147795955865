import React from 'react';

const htmlString = `
<section class="py-5 my-5"
        style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
        <div class="col-area" style=" margin-top: 5%">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Healthcare &<br> <span class="us-text"
                                    style="color: #059FE7;font-weight: 1000;">Analytics</span></h4>
                            <p class="text-p mt-3" style="padding-left:45px;">An Innovative Approach to Revenue Measurement and Collection Maximization</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/health.png" class="w-75" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<div class="container connect mt-4">
<div class="row" style="margin-top:5%;margin-bottom:5%;">
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-star fa-3x mb-2"></i><br>
                <h2> Dynamic dashboard </h2>
                <p style="text-align:justify;"> Tailor your in-depth medical billing reports with a
                    user-friendly drag-and-drop interface. Gain real-time insights into your financial
                    well-being using RDS Precision for a quick overview </p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2> Recomended reports </h2>
                <p style="text-align:justify;"> Personalize and explore our selection of recommended reports
                    within our dashboard, featuring expert descriptions from the RDS Precision team. </p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-globe fa-3x mb-2"></i>
                <h2> Modren interface </h2>
                <p style="text-align:justify;"> Achieving financial success begins with advanced medical billing
                    software that offers intuitive, clean, and user-friendly data visualization for in-depth
                    analysis </p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-camera fa-3x mb-2"></i>
                <h2> Indepth analysis </h2>
                <p style="text-align:justify;"> With RDS Precision support, you have unrestricted and mobile
                    access to standard dashboards and universally applicable solutions </p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-cog fa-3x mb-2"></i>
                <h2> Analyze your workflow </h2>
                <p style="text-align:justify;"> Enhance efficiency by leveraging data-driven insights to boost
                    your profitability </p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:400px;box-shadow:10px 10px 10px rgba(0, 0, 0, 0.1);">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2> Accelerate your reimbursement time </h2>
                <p style="text-align:justify;"> A significant part of improving your website is deciding what
                    you want to focus on as a company. By discovering what your patients are looking for and
                    making sure. </p>
            </div>
        </div>
    </div>
</div>
</div>
<div class="ar-t bg-grey py-5" style="margin-top:5%;">
<div class="container">
    <h4 class="text-center text-darker mb-3" style="color:black;">The most advanced way of <span class="us-text"
            style="font-weight: 800;color: #059FE7;">healthCare analytics</span></h4>
    <p class="text-center text-p">RDS Precision offers an all-encompassing view of your account, encompassing
        appointment requests and upcoming appointments. Our RDS Precision Practice Analytics Dashboard equips
        you with the tools to quantify success and immediate access to essential data. <br><br> Through our
        Charts feature, you gain a holistic snapshot of a patient's entire medical history and clinical summary
        at a single glance. This allows for more meaningful patient interactions and the ability to plan more
        effective treatments. </p>
</div>
</div>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;
function Analytics() {
  console.log('Analytics called');


  const handleClick = (path) => {

    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

 
  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Analytics;
