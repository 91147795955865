import React from 'react';

const htmlString = `
<section class="py-5 my-5"
        style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
        <div class="col-area" style=" margin-top: 5%">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Practice management &<br> <span class="us-text"
                                    style="color: #059FE7;font-weight: 1000;">Medical billing control center</span></h4>
                            <p class="text-p mt-3" style="padding-left:45px;">RDS Precision offers a comprehensive practice management and medical billing solution designed to
                                seamlessly address the needs of both private healthcare practices and billing services</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/prc.webp" class="w-75" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<div class="ar-t" style="background-color: #ebeff5; padding: 35px;" >    
<div class="container">
    <div class="row">
        <div class="col-md-6 mb-4">
            <div class="reportcenter" style="box-shadow: 10px 10px 10px #c5c0c0;">
                <div><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i></div>
                <div class="report-content px-4">
                    <h4 class="small-heading-4">Made for business</h4>
                    <p class="text-p p-0 m-0">RDS Precision efficiently handles your daily financial
                        transactions with cutting-edge capabilities</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-4">
            <div class="reportcenter" style="box-shadow: 10px 10px 10px #c5c0c0;">
                <div><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i></div>
                <div class="report-content px-4">
                    <h4 class="small-heading-4">Secure &amp; easy</h4>
                    <p class="text-p p-0 m-0">RDS Precision handles your daily financial operations with
                        advanced capabilities.</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-4">
            <div class="reportcenter" style="box-shadow: 10px 10px 10px #c5c0c0;">
                <div><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i></div>
                <div class="report-content px-4">
                    <h4 class="small-heading-4">Live within minutes</h4>
                    <p class="text-p p-0 m-0">RDS Precision efficiently oversees your day-to-day financial
                        operations with state-of-the-art capabilities</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-4">
            <div class="reportcenter" style="box-shadow: 10px 10px 10px #c5c0c0;">
                <div><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i></div>
                <div class="report-content px-4">
                    <h4 class="small-heading-4">Great customer care</h4>
                    <p class="text-p p-0 m-0">RDS Precision efficiently handles your daily financial operations
                        with state-of-the-art capabilities</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="help-you" style="background-color:rgb(250, 246, 246);">
<div class="container" style="padding-top:5%;padding-bottom:6%;">
    <div class="row pt-3 pb-3">
        <div class="col-md-6"><img src="assets/img/departments-5.jpg" class="w-90" alt="" style="width:80%;">
        </div>
        <div class="col-md-6 d-flex align-items-center">
            <h4 class="text-darker w-sm-100" style="width:450px;color:black;">Increase revenue profitability
                with a <span class="us-text" style="color: #059FE7;font-weight: bold;">comprehensive practice
                    management solution</span></h4>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-md-4">
            <p class="text-p">A versatile practice management solution that seamlessly integrates medical,
                operational, and financial aspects for unique care organizations. Whether you aim to enhance
                patient care or boost financial returns, RDS Precision offers the flexibility and accessibility
                to empower your practice</p>
        </div>
        <div class="col-md-4">
            <div class="list-service">
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>State
                        Medicare &amp; Mediciad enrollment including RR Medicare, Tricare, BWC</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>Commercial
                        payers' enrollment (UHC, Aetna, Cigna, etc)</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        Re-validation &amp; Re-credentialing (Fedral &amp; Commercial Payers)</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        NPI New Application &amp; NPI Update</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        Contact's ADDITION &amp; Negotiation</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        Fee Schedule Request</span></div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="list-service">
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>EDI,
                        ERA EFT setups</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        CAQH creation &amp; maintenance including re-attestation &amp; documents</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        State Medicare &amp; Mediciad enrollment including RR Medicare, Tricare, BWC</span>
                </div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        Hospital Privileges</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>
                        Insurance Portal Creation</span></div>
                <div class="list-line"><i class="fas fa-check"
                        style="color: white;background-color: #059FE7;border-radius: 12px;padding: 3px;"></i><span>State
                        License Appplication &amp; Renewal</span></div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="ar-t py-5">
<div class="container">
    <h4 class="text-center text-darker mb-3" style="color:black;">The Most advanced way Of <span class="us-text"
            style="color: #059FE7;font-weight: bold;">healthCare analytics</span></h4>
    <p class="text-center text-p">RDS Precision offers a holistic account overview, encompassing appointment
        requests and upcoming appointments. The RDS Precision Practice Analytics Dashboard enables you to gauge
        performance and access essential data instantly. <br><br> Utilizing Charts, you gain a comprehensive
        insight into a patient's entire medical history and clinical summary at a glance. This empowers you to
        establish more meaningful patient connections and enhance treatment planning. </p>
</div>
</div>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;
function PracticeManagement() {
  console.log('PracticeManagement called');


  const handleClick = (path) => {

    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

 
  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default PracticeManagement;
