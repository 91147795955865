import React from 'react';
import logo2 from "../assets/img/log0.png";


const htmlString = `
<section
style="background: url(&quot;assets/img/abcd.png&quot;) center / cover no-repeat;height: 100vh;padding-top: 80px;">
<div class="m-auto w-lg-75 w-xl-50"
    style="padding: 47px;margin: 165.9px 336.698px;margin-top: 100.3px;padding-top: 0px;height: 518.3px;width: 350.7px;border-width: 0px;border-color: gray;">
    <div class="row justify-content-center" style="margin-bottom:37px;">
        <div class="col-auto align-items-center align-content-center" style="/*text-align:center;*/"><img src="${logo2}" alt="Logo" 
        data-bss-hover-animate="rubberBand"
       style="/*text-align: center;*/height:12vh;color: #059FE7;"/></div>
    </div>
    <p style="color: #059FE7;font-size: 36px;font-weight: bold;width: 296.7px;margin-left: -25px;">Reset
        Password</p>
    <form>
        <div class="form-group mb-3">
        <label class="form-label text-secondary"
                                  style="color: rgb(169,169,169) !important; --bs-body-font-weight: bold; --bs-body-font-size: 1rem;font-size: 20px;font-weight: bold;">
         Email Address
                </label>
                <input class="form-control" type="text" required=""
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$" inputmode="email">
                </div>
        <div class="row justify-content-end">
            <div class="col-auto col-lg-12 d-flex flex-column justify-content-center align-items-start align-content-center align-self-center"
                style="/*text-align: right;*/">
                <p class="text-end mt-3 mb-0"
                    style="width: 160.7px;margin-left: 117px;padding-top: 0px;padding-bottom: 0px;margin-top: 0px;">
                </p>
                
                <button data-bss-hover-animate="rubberBand" class="see-all-button"
                    style="color: #059FE7;border-color: #059FE7;padding-left: 20px;margin-left: 0px;margin-right: 170px;margin-top: -5px;width: 147.2px;">Send
                    Email</button>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto col-lg-12 d-flex flex-column justify-content-center align-items-start align-content-center align-self-center"
                style="/*text-align: right;*/">
                <a class="link-danger text-info small" href="#"
                    style="margin-bottom: 0px;margin-top: 22px;padding-top: 0px;font-weight: bold;" onclick="handleClick('./Login')">Back to
                    Login</a>
                    </div>
        </div>
    </form>
</div>
</section>
`;


function Reset() {
    console.log("Reset called");
    const handleClick = (path) => {

        console.log(`Navigating to: ${path}`);

        window.location.href = path;
    };


    window.handleClick = handleClick;

    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Reset;
