import React from 'react';
import oncology from '../assets/images/common/oncology.jpg'
const htmlString = `
<section class="denter" style="width: 100%; height: 30vh; background-image: url('${oncology}'); background-size: cover; background-position: center;">
<div class="container">
      <h1 style=" margin-top: 120px; color: #059FE7; font-family: 'Noto Sans Canadian Aboriginal', sans-serif; padding: 3px; font-size: 42px; font-weight: bold;"> Oncology : </h1>
  </div>
</section>
<section class="bass" style=" margin-top: 50px;">
<div class="ar-t py-5">
<div class="container">
<h2 style="font-weight: bold;">Innovative revenue cycle management solutions for oncology practices</h2>
<p>
We assemble a dedicated team comprising oncology experts, billing professionals, and process specialists aimed at enhancing the financial vitality of your practice and bolstering revenue streams. Our approach involves collaborating closely with you to pinpoint strategic objectives, fostering new avenues for revenue generation, and streamlining expenses.
<br>
 With our comprehensive service, we ensure a tailored strategy to elevate your practice's financial performance.

</p>
<h2 style="font-weight: bold;">We are more than a billing service</h2>
<p>
We're dedicated to streamlining and enhancing the billing and payment process in oncology. Our solutions revolve around refining processes and integrating technologies to boost revenue.
<br>
We're truly enthusiastic about the tangible benefits these services offer to our community oncology partners. They empower practice teams, driving performance, and Flatiron stands out as a leader in revenue cycle solutions.
<br>
</p>
<h4 style="color: black;font-weight: bold;">
Here's what we offer:
</h4>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Core billing services.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Advanced practice analytics.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Change management support.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Optimization of clinical and business workflows.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Services focused on expanding revenue</p>

<h2 style="font-weight: bold;">Focus on patient care and let us do the rest</h2>
<p>
To boost your team's capabilities and streamline operations, ___ offers revenue cycle management solutions aimed at enhancing your practice's financial health.
<br>
</p>
<div>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
 Drive revenue growth.</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Optimize revenue streams.</p>

</div>
<p>
Our solutions provide your practice with the flexibility and outreach needed to elevate service quality for your patients. They also ensure you stay ahead of compliance requirements by improving collections.
<br>
</p>
<div>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Performance analysis.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Personalized dashboard.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Billing insights.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Quality metrics.</p>
</div>
</div>
</div>
</section>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;

function Oncology() {
    console.log("Oncology called");
    const handleClick = (path) => {
  
      console.log(`Navigating to: ${path}`);
  
      window.location.href = path;
    };
  
  
    window.handleClick = handleClick;
  
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }
export default Oncology;