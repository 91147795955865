import React from 'react';

const htmlString = `
<section class="py-5 my-5"
        style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
        <div class="col-area" style=" margin-top: 5%">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Revenue Cycle<br> <span class="us-text"
                                    style="color: #059FE7;font-weight: 1000;">management (RCM)</span></h4>
                            <p class="text-p mt-3" style="padding-left:45px;">Comprehensive Revenue Cycle Management (RCM) Services by RDS : A single error can lead to payment
                                loss, and non-compliance with established Standard Operating Procedures (SOPs) may impact
                                medical practice licensing</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/re.webp" class="w-75" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<div class="container text-center" style="margin-top:5%;">
<h1 style="color:black;">The Comprehensive &amp; Adaptable Revenue Cycle Management <span
        style="color: #059FE7;font-weight: bold;">RCM solution</span></h1>
<h5 style="padding:1%;color:rgb(85, 83, 83);">Our Consultative Flexibility, Full-Spectrum Services, and
    Specialized Back-Office Team Are Tailored to Address Your Unique Requirements. Allow Us to Enhance Your
    Medical Group's Profitability and Alleviate Administrative Challenges</h5>
</div>
<div class="container mt-4">
<div class="row">
    <div class="col-md-4">
        <div class="card-body cardd" style="height: 85%;">
            <div class="text-left"><i class="fas fa-star fa-3x mb-2"></i>
                <h2>Patient Appointmment</h2>
                <p style="text-align:justify;">The inception of Revenue Cycle Management (RCM) commences with
                    the patient appointment process, which can be scheduled via phone with the submission of
                    essential patient demographics and insurance details.</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:85%;">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2>Eligibility &amp; banefits verification</h2>
                <p style="text-align:justify;">Following the appointment, we conduct a thorough review of the
                    patient's insurance coverage to confirm whether the services provided will be eligible for
                    insurance reimbursement.Following the appointment, we conduct a thorough review of the
                    patient's insurance coverage to confirm whether the services provided will be eligible for
                    insurance reimbursement.</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:85%;">
            <div class="text-left"><i class="fas fa-globe fa-3x mb-2"></i>
                <h2>Medical Transcription</h2>
                <p style="text-align:justify;">Medical transcription involves the transformation of recorded
                    services into medical records. These records not only substantiate the provided services but
                    are also utilized by insurance companies to assess the medical necessity of the services</p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-camera fa-3x mb-2"></i>
                <h2>Medical coding</h2>
                <p style="text-align:justify;">Medical coding involves a comprehensive review of medical
                    records, where medical coders translate the information into diagnostic codes using systems
                    such as ICD, procedures, CPT, and HCPCS. Medical coders are responsible for managing and
                    applying these codes</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-cog fa-3x mb-2"></i>
                <h2 style="letter-spacing:0;">Charge entry &amp; billing</h2>
                <p style="text-align:justify;">Charge entry and billing encompass the gathering of all necessary
                    information required to complete the claim, and the subsequent step involves submitting the
                    claim form</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2>Claim submission</h2>
                <p style="text-align:justify;">Claim submission is the next step following charge entry, where
                    the claim form is forwarded to the insurance company for payment. This can be done through
                    various methods, including paper submissions, electronic claims, and online data entry.</p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-camera fa-3x mb-2"></i>
                <h2>Claim rejection</h2>
                <p style="text-align:justify;">Claim rejections may occur for various reasons. Medical billing
                    professionals carefully examine and rectify rejected claims before resubmitting them to the
                    insurance company</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-cog fa-3x mb-2"></i>
                <h2>Payment and posting</h2>
                <p style="text-align:justify;">Payment and posting involve adhering to approved payment
                    guidelines for claims. Once a claim is approved, the insurance company sends a payment along
                    with an Explanation of Benefits (EOB) or an Electronic Remittance Advice (ERA).</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2>Secondry claim submission</h2>
                <p style="text-align:justify;">Secondary claim submission becomes necessary because the
                    patient's primary insurance typically does not cover 100% of the allowed amount. In this
                    case, the medical biller proceeds to submit the claim to the patient's secondary insurance
                </p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-camera fa-3x mb-2"></i>
                <h2>Denial management</h2>
                <p style="text-align:justify;">Denial management is a crucial part of the revenue cycle,
                    although it may seem seamless. Numerous claims are denied for various reasons, and it falls
                    upon the expertise of medical billing professionals to rectify and address these denials.
                </p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-cog fa-3x mb-2"></i>
                <h2>Medical appeals</h2>
                <p style="text-align:justify;">Medical appeals come into play when an insurance company denies a
                    claim, and medical billers operate within the framework of regulatory guidelines for claim
                    denials. Some insurers also provide the option for second-level appeals</p>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card-body cardd" style="height:90%;">
            <div class="text-left"><i class="fas fa-heart fa-3x mb-2"></i>
                <h2>Refunds (insurance and patient)</h2>
                <p style="text-align:justify;">Refunds (for both insurance and patients) in medical billing
                    involve the process of returning money to either the patient or the insurance company. This
                    can be categorized into Patient Refunds and Insurance Refunds</p>
            </div>
        </div>
    </div>
</div>
</div>
<section>
<div class="container">
    <div class="row" >
        <div class="col-md-4 mb-4" style="width: 100%;">
            <div class="card-body" style="text-align:center;">
                <h2 class="card-title mb-3" style="font-weight:bold; ">Where we can <span
                        style="color: #059FE7;font-weight: bold;"> help you? </span></h2>
                <h5 class="card-text">We handle everything from the process of submitting and following up on
                    claims with health insurance companies to recieve payment for services rendered by a
                    healthcare provider or medical billing company.</h5>
            </div>
        </div>

    </div>
</div>
</section>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;


function Revenue() 
{
    console.log( "Revenue called" );
    const handleClick = (path) => {

        console.log(`Navigating to: ${path}`);
    
        window.location.href = path;
      };
    
     
      window.handleClick = handleClick;
    
      return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    }

export default Revenue;
