import React from 'react';
import dermatology from '../assets/images/common/Dermatology.jpg'
const htmlString = `
<section class="denter" style="width: 100%; height: 30vh; background-image: url('${dermatology}'); background-size: cover; background-position: center;">
<div class="container">
      <h1 style=" margin-top: 120px; color: #059FE7; font-family: 'Noto Sans Canadian Aboriginal', sans-serif; padding: 3px; font-size: 42px; font-weight: bold;"> Dermatology : </h1>
  </div>
</section>
<section class="bass" style=" margin-top: 50px;">
<div class="ar-t py-5">
<div class="container">
<h2 style="font-weight: bold;">Comprehensive management of the revenue cycle tailored specifically for dermatology practices</h2>
<p>
Our team of billing experts is well-versed in the intricacies of your specialty, along with the distinct requirements of your patients.
<br>
We oversee the full spectrum of your revenue cycle across various channels, ensuring:
</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Complete accessibility to data.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Tracking of claims in real-time.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Effective denial management.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
In-depth financial analytics.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Verification of eligibility.</p>
<br>
<p>
At every phase of patient interaction, our revenue cycle management software operates efficiently in the background, streamlining all facets of dermatology revenue cycle management with features
<h4 style="color: black;font-weight: bold;">
such as:
</h4>
</p>
<div>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Insurance verification.</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Automated coding for encounters.</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Automated posting of payments.</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
This comprehensive approach ensures a seamless and optimized .</p>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
financial workflow for your practice..</p>
</div>        
</div>
</div>
</section>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;

function Dermatology() {
        console.log("Dermatology called");
        const handleClick = (path) => {
      
          console.log(`Navigating to: ${path}`);
      
          window.location.href = path;
        };
      
      
        window.handleClick = handleClick;
      
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
      }
export default Dermatology;