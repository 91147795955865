import React from 'react';

const htmlString = `
<section class="py-5 my-5"
style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
<div class="col-area" style=" margin-top: 5%">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="col-content">
                    <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Patient experience<br> <span class="us-text"
                            style="color: #059FE7;font-weight: 1000;">management</span></h4>
                    <p class="text-p mt-3" style="padding-left:45px;">Craft an all-encompassing patient journey with RDS Precision.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center img-col"><img src="assets/img/health.png" class="w-75" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
</section>
    <div class="ar-t bg-grey" style="background-color:rgba(242, 242, 243, 0.945);padding:7%;">
        <div class="container">
            <h4 class="text-center text-darker" style="color:black;font-weight:600;">Grow your practice by end-to-end
                <span class="us-text" style="color: #059FE7;font-weight: 800;">patient experience</span></h4>
            <p class="text-center text-p">RDS Precision provides a holistic solution to enable transparent, high-quality
                interactions with patients, ensuring they receive the care they deserve.<br><br> By implementing RDS
                Precision, you can boost upfront revenue and deliver a transparent, all-encompassing, positive patient
                experience. In the broader context </p>
        </div>
    </div>
    <div class="row" style="background-color: #059FE7;padding-top: 40px;padding-bottom: 60px;">
        <div class="col">
            <div class="container">
                <div class="row">
                    <div class="category-sec">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">01</div>
                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Create patient experience evaluations.</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">02</div>

                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Distinguish areas of excellence and areas for
                                                improvement</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">03</div>

                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Formulate enhancement strategies</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">04</div>

                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Assets for suggested transformation</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">05</div>

                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Evaluate the efficacy of the enhancement plan
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="service-card">
                                        <div class="numbering-service">06</div>

                                        <div class="service-inner">
                                            <h5 class="small-heading-s">Commend patient achievements</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="py-5 my-5">
        <div class="col-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;">Book An appointment <br><span
                                    class="us-text" style="color: #059FE7;font-weight: 1000;">that’s convenient For
                                    You.</span></h4>
                            <p class="text-p mt-3">Healthcare systems globally, in collaboration with government
                                agencies and innovative startups, are increasingly developing and providing telehealth
                                solutions. <br><br> With our service, you can conveniently consult with one of our
                                friendly and well-informed doctors online, no matter your location or how hectic your
                                schedule may be. </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/book.jpeg" class="w-75" alt=""></div>
                    </div>
                </div>
                <div class="row mt-5 pt-5">
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/praccc.jpg" class="w-75" alt=""></div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;">Make your practice more
                                <br><span class="us-text" style="color: #059FE7;font-weight: 1000;"> worhwhile &amp;
                                    rewarding</span></h4>
                            <p class="text-p mt-3">To be an exemplary healthcare provider, alignment with the
                                organization's values and vision, along with the necessary resources for effective
                                delivery, is crucial. RDS Precision stands as the optimal tool and service for
                                maximizing the profitability of your practice. With over six years of demonstrated
                                expertise, Carevma is a trusted partner for complex health systems </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="ar-t bg-grey py-5">
        <div class="container">
            <h4 class="text-center text-darker mb-3" style="color:black;font-weight:600;">An explosive way to empower
                your practice as a <br><span class="us-text" style="color: #059FE7;font-weight: 1000;"> healthcare
                    provider</span></h4>
            <p class="text-center text-p">The RDS Precision RCM team will process authorizations for multiple visits,
                enabling you to create a well-informed treatment or care plan. We also manage patient eligibility
                verifications through the clearinghouse, using patient demographic data to proactively address and
                reduce claims denials </p>
            <div class="row pt-5">
                <div class="col-md-6 mb-4">
                    <div class="cate-inner" style="box-shadow: 10px 10px 10px #c5c0c0;">
                        <div class="cat-content"><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i>
                            <h4 class="small-heading-3 px-3">Eligibility verification</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="cate-inner" style="box-shadow: 10px 10px 10px #c5c0c0;">
                        <div class="cat-content"><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i>
                            <h4 class="small-heading-3">Coverage detection</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="cate-inner" style="box-shadow: 10px 10px 10px #c5c0c0;">
                        <div class="cat-content"><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i>
                            <h4 class="small-heading-3">Cause of claims denial</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="cate-inner" style="box-shadow: 10px 10px 10px #c5c0c0;">
                        <div class="cat-content"><i class="fas fa-copy copy-icon" style="color: #059FE7;"></i>
                            <h4 class="small-heading-3">Patient estimation</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-area" style="padding-top:5%;padding-bottom:5%;">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="col-content">
                        <h4 class="text-darker" style="color:black;font-weight:600;">Pick up your prescription <br><span
                                class="us-text" style="color: #059FE7;font-weight: 1000;"> from your local doctor</span>
                        </h4>
                        <p class="text-p mt-3">Healthcare systems globally, in partnership with government agencies and
                            innovative startups, are developing and offering telehealth services.<br><br> Our service
                            includes the convenient option to send your prescription directly to your local pharmacy for
                            easy pick-up. This is a collaborative effort with healthcare systems around the world and
                            government agencies </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-center img-col"><img src="assets/img/departments-3.jpg" class="w-75" alt=""></div>
                </div>
            </div>
        </div>
    </div>
    <section style="background-color: #059FE7;">
        <div class="ar-t bg-blue py-5" style="background-color: #059FE7;">
            <div class="container">
                <h4 class="text-center text-white text-darker mb-3">Create a superior patient experience by faster
                    claims</h4>
                <p class="text-center text-white text-p">Our adaptable, consultative strategy, along with our
                    comprehensive suite of services and a dedicated back-office team, directly aligns with your
                    requirements and delivers technological solutions. <br><br> Our Insurance Claims Management and
                    Reimbursement services are designed to boost collections and optimize your practice. Allow us to
                    enhance your organization's financial performance and alleviate administrative burdens </p>
            </div>
        </div>
    </section>
    <section class="py-5 my-5">
        <div class="col-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-center img-col"><img src="assets/img/chat.webp" class="w-75" alt=""></div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Chat with our
                                care team <br><span class="us-text" style="color: #059FE7;font-weight: 1000;"> anytime
                                    you need to</span></h4>
                            <p class="text-p mt-3" style="padding-left:45px;">Obtain valuable insights and address your
                                concerns conveniently through unlimited messaging with your dedicated physician and our
                                care team directly within our app. Experience personalized care at your fingertips</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
    <div class="row text-center" style="color:white; 100%;  width: 100%; max-width: 1440px;">
      <h2 style="font-weight:bolder;">Ready to Talk?</h2>
      <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
        <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
      </div>
      <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
        <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
      </div>
      <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
        <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
      </div>
      <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
        <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
      </div>
    </div>
    </div>

`;
function Experience() {
  console.log('Experience called');


  const handleClick = (path) => {

    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

 
  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default Experience;
