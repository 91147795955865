import { Route, Routes, useLocation } from "react-router";
import React, { useState, useEffect } from "react";
import Footer from "./components/common/Footer";
import NavBar from "./components/common/NavBar";
// import Sidebare from "./components/admin/admin_files/Home";
import HomePage from "./containers/HomePage";
import Revenue from "./containers/Revenue";
import Contact from "./containers/Contact";
import Digitalmarketing from "./containers/Digitalmarketing";
import Analytics from "./containers/Analytics";
import Experience from "./containers/Experience";
import PracticeManagement from "./containers/PracticeManagement";
import Login from "./components/admin/login_files/Login";
import Register from "./components/admin/login_files/Register";
import Reset from "./components/admin/login_files/Reset";
import Privacy from "./containers/Privacy";
import Descrimation from "./containers/Descrimation";
import HIPAA from "./containers/HIPAA";
import Specialities from "./containers/Specialities"
import Oncology from "./containers/Oncology";
import GYN from "./containers/GYN";
import Cardiology from "./containers/Cardiology";
import Dermatology from "./containers/Dermatology";
import Pediatrics from "./containers/Pediatrics";
import Psychiatry from "./containers/Psychiatry";
import Pulmonology from "./containers/Pulmonology";
import Orthopedic from "./containers/Orthopedic";
import Neurology from "./containers/Neurology";
import Medicine from "./containers/Medicine";
import Endocrinology from "./containers/Endocrinology";
import Therapy from "./containers/Therapy";
import Anesthesiology from "./containers/Anesthesiology";
import Chiropractic from "./containers/Chiropractic";
import Dental from "./containers/Dental";
import DMC from "./containers/DMC";
import FQHC from "./containers/FQHC.JS";
import Gastroenterology from "./containers/Gastroenterology";
import Surgery from "./containers/Surgery";
// import Medical from "./containers/Medical";


import RegisterPage from './RegisterPage';
import VerifyPage from './LoginPagea';


const App = () => {
  const [routes_records, setRoutesRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {

    const fetchData = async () => {

     

      try {

       // Retrieve token from localStorage
    const token = localStorage.getItem("token");

    if (!token) {
      // Handle the case where the token is not available
      throw new Error("Token not found in localStorage");

    }

        const response = await fetch("https://med-b.ealamgroup.pk/record/app_tab", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,            // Add other headers if needed
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setRoutesRecords(data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const location = useLocation();

  const isRestrictedRoute = () =>
  ["NavBar2","Permissions"].includes(location.pathname.slice(1));

const isDynamicRoute = (routeName) =>
  routes_records.some((route) => route.name === routeName);

const shouldRenderNavBar2 = () => isRestrictedRoute() || isDynamicRoute(location.pathname.slice(1));

const shouldRenderFooter = () => {
  const excludedPages = ["Login", "Reset", "Register", "Contact","Permission","NavBar2","Privacy"];
  return !excludedPages.includes(location.pathname.slice(1)) && !isDynamicRoute(location.pathname.slice(1));
};

if (loading) {
  return null;
}
  return (
    <div className="App">
      {!shouldRenderNavBar2() && <NavBar />}
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Revenue" element={<Revenue />} />
          <Route path="/Digitalmarketing" element={<Digitalmarketing />} />
          <Route path="/Analytics" element={<Analytics />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/PracticeManagement" element={<PracticeManagement />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Reset" element={<Reset />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Descrimation" element={<Descrimation />} />
          <Route path="/HIPAA" element={<HIPAA />} />
          <Route path="/Specialities" element={<Specialities />} />
          <Route path="/Oncology" element={<Oncology />} /> 
          <Route path="/GYN" element={<GYN />} />
          <Route path="/Cardiology" element={<Cardiology />} />
          <Route path="/Dermatology" element={<Dermatology />} />
          <Route path="/Pediatrics" element={<Pediatrics />} /> 
          <Route path="/Psychiatry" element={<Psychiatry />} />
          <Route path="/Pulmonology" element={<Pulmonology />} />
          <Route path="/Orthopedic" element={<Orthopedic />} />
          <Route path="/Neurology" element={<Neurology />} /> 
          <Route path="/Medicine" element={<Medicine />} />
          <Route path="/Endocrinology" element={<Endocrinology />} />
          <Route path="/Therapy" element={<Therapy />} />
          <Route path="/Anesthesiology" element={<Anesthesiology />} />
          <Route path="/Chiropractic" element={<Chiropractic />} />
          <Route path="/Dental" element={<Dental />} />
          <Route path="/DMC" element={<DMC />} />
          <Route path="/FQHC" element={<FQHC />} />
          <Route path="/Gastroenterology" element={<Gastroenterology />} />
          <Route path="/Surgery" element={<Surgery />} />
          <Route path="/RegisterPage" element={<RegisterPage />} />


          <Route path="/" component={RegisterPage} />
          <Route path="/register" component={RegisterPage} />






          </Routes>
    </main>

    {/* Dynamic routes outside of the main section */}
    <Routes>
      {routes_records.map((route) => (
        <Route
          key={route.name}
          path={`/${route.name}`}
          // element={<NavBar2 label={route.label} name={route.name} />}
        />
      ))}
    </Routes>
    {shouldRenderFooter() && <Footer />}
    </div>
  );
};

export default App;
// {shouldRenderNavBar2() ? <Sidebare /> : null}
