import React from "react";

const htmlString = `
<header id="header" class="fixed-top" style="
top: 20px;
margin-right: 12%;
margin-left: 12%;
border-radius: 100px;
height:75px;
width">
<div class="container d-flex align-items-center" style="margin-top: 0px;height: 40.3px;"><a  onclick="handleClick('./')"><img
      class="me-auto logo" src="assets/img/logo-01.svg" style="width: 30.4px;height: 55.3px;"></a>
      <a  onclick="handleClick('./')"><img
        class="me-auto logo" src="assets/img/text.svg" style="width: 150.4px;height: 55.3px;"></a>
  <nav class="order-last navbar order-lg-0" id="navbar" style="margin-left:auto;">
    <ul>
      <li class="dropdown"><a><span style="font-weight: 600;font-size: 18px;color: black;">Solutions</span><i class='bx bxs-chevron-down bx-tada bx-flip-horizontal' ></i></a>
        <ul style="border-radius: 25px">
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Revenue')">Revenue Cycle Management</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Digitalmarketing')">Healthcare Digital Marketing</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Analytics')">Healthcare Analytics</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Experience')">Patient Experience Management</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./PracticeManagement')">Practice Management</a></li>
        </ul>
      </li>
      <li class="dropdown"><a><span style="font-weight: 600;font-size: 18px;color: black;">Services</span><i class='bx bxs-chevron-down bx-tada bx-flip-horizontal' ></i></a>
        <ul style="border-radius: 25px">
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Medical')">Medical Billing</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Digitalmarketing')">Medical Coding</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Analytics')">Account Receivables A/R</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./Experience')"> Credentialing</a></li>
          <li><a style="font-size: 16px;font-weight: 600;" onclick="handleClick('./PracticeManagement')">Denial Management</a></li>
        </ul>
      </li>
      <li><a class="nav-link scrollto" style="font-weight: 600;font-size: 18px;color: black;" onclick="handleClick('/Contact')">Pricing</a></li>
      <li><a class="nav-link scrollto" style="font-weight: 600;font-size: 18px;color: black;" onclick="handleClick('/Contact')">Request a Demo</a></li>
      <li><button data-bss-hover-animate="rubberBand" class="see-all-button" 
      style="background-color: transparent;font-weight: 600;font-size: 18px;color: #059FE7; border-color: #059FE7; padding-left: 20px; margin-left: 0px; margin-right: 0px; margin-top: 0px;" 
      onclick="handleClick('/Login')">Login</button></li>
    </ul><i class="bi bi-list mobile-nav-toggle" style="cursor: pointer;"></i>
    </nav>
</div>
</header>
`;
function NavBar() {
  console.log("Nav called");

  const handleClick = (path) => {
    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };

  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default NavBar;
