import React from 'react';
import cardiology from '../assets/images/common/Cardiology.webp'
const htmlString = `
<section class="denter" style="width: 100%; height: 30vh; background-image: url('${cardiology}'); background-size: cover; background-position: center;">
<div class="container">
      <h1 style=" margin-top: 120px; color: #059FE7; font-family: 'Noto Sans Canadian Aboriginal', sans-serif; padding: 3px; font-size: 42px; font-weight: bold;"> Cardiology : </h1>
  </div>
</section>
<section class="bass" style=" margin-top: 50px;">
<div class="ar-t py-5">
<div class="container">
<h2 style="font-weight: bold;">Enhance your revenue cycle efficiency through the expertise of certified billing professionals.</h2>
<p>
As a cardiologist, your focus is on one of the body's most critical systems, frequently encountering patients at a stage where their cardiac health is compromised. Given the complexity and variety of potential procedures in this specialty, it is imperative that your billing team possesses a comprehensive understanding of the specific coding nuances relevant to cardiology.
<br>
Entrusting either a portion or the entirety of your practice’s revenue cycle management to a seasoned medical practice management firm enables you to dedicate more time to patient care, all the while optimizing your practice’s revenue potential.
</p>
<h2 style="font-weight: bold;">Our mission is to efficiently streamline the revenue cycle process, enabling physicians and their teams to concentrate on delivering high-quality care and ensuring patient satisfaction and well-being</h2>
<p>
We excel in the field of International Cardiology, specializing in precise medical coding, meticulous billing practices, and effective collection strategies. Our commitment to accuracy, thoroughness, and adherence to legal standards consistently leads to enhanced revenue generation.
</p>
<h4 style="color: black;font-weight: bold;">
Our services include:
</h4>
<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Conducting pre-appointment batch verifications to confirm eligibility and maximize benefits for patients.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Providing comprehensive financial and management analytics tailored specifically for Cardiology practices.</p>

<p><i class="fas fa-check" style="color: white;background-color: #059FE7;border-radius: 50px;padding: 3px; font-size: 10px;"></i>
Implementing meticulous tracking mechanisms to monitor and ensure the seamless processing of your claims.</p>

<h2 style="font-weight: bold;">We develop sophisticated paid lifecycle solutions for healthcare providers, optimizing pre-authorization workflows across hospitals, clinics, imaging centers, and laboratories</h2>
<p>
Our comprehensive suite of patient payment and medical billing solutions is designed to expedite the billing collection process, enhance cash flow, and effectively maintain revenue integrity across the entire payment lifecycle.
</p>
</div>
</div>
</section>
<div class="connect" style="background-color: #059FE7;padding: 50px 20px; display: flex; justify-content: center;">
<div class="row text-center" style="color:white; width: 100%; max-width: 1440px;">
  <h2 style="font-weight:bolder;">Ready to Talk?</h2>
  <div class="col-md-3 conn"><i class="fas fa-calendar icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Schedule a Demo</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-connectdevelop icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Connect with Sales</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-comment-dots icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Chat with Us</a></h4>
  </div>
  <div class="col-md-3 conn"><i class="fas fa-chart-line icon"></i>
    <h4><a style="color:white;" onclick="handleClick('./Contact')">Request an Analysis</a></h4>
  </div>
</div>
</div>
`;

function Chiropractic() {
        console.log("Chiropractic called");
        const handleClick = (path) => {
      
          console.log(`Navigating to: ${path}`);
      
          window.location.href = path;
        };
      
      
        window.handleClick = handleClick;
      
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
      }
export default Chiropractic;