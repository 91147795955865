import React from 'react';
const htmlString = `


<section class="py-5 my-5" style="background:white;padding-top:25%;margin-top:50%;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;">
        <div class="col-area" style=" margin-top: 5%">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-content">
                            <h4 class="text-darker" style="color:black;font-weight:600;padding-left:45px;">Emphasize your expertise; <br> <span class="us-text" style="color: #059FE7;font-weight: 1000;">leave the rest in our
                                capable hands</span></h4>
                            <p class="text-p mt-3" style="padding-left:45px;">Reclaim your time for patient care by using
                                RDS Precision to handle your medical billing.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                    <div class="text-center img-col"><img src="assets/img/page_Experience.svg" class="w-75" alt=""></div>
                </div>
                </div>
            </div>
        </div>
        </section>

<section id="basic" class="basics">
<div class="container search">
  <div class="row row-search">
    <div class="col-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11 search-box">
      <div class="search-heading">
        <span>Built for your specialities</span>
      </div>
      <select class="search-dropdown" onchange="window.location.href = this.value;">
        <option value="" disabled selected>Find your specialty</option>
        <option value="./Oncology">Oncology</option>
        <option value="./GYN">OB/GYN</option>
        <option value="./Cardiology">Cardiology</option>
        <option value="./Dermatology">Dermatology</option>
      </select>
      <button data-bss-hover-animate="rubberBand" class="see-all-button"
              style="color: #059FE7;border-color: #059FE7;"" onclick="handleClick('/Specialities')"
              >See All Specialities
          </button>
      </div>
  </div>
</div>
</section>
<section id="services" class="services">
<div class="container">
  <div class="section-title">
  <h1 class="text-center second" style="font-weight:bold; margin-top:20px;">RDS <span style="margin-left: 7px;">Precision</span><span class="second"
  style="color: #059FE7; margin-left: 9px;"">Billing</span></h1>
        <br><br>
    <div class="row">
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fa-solid fa-hand-holding-droplet"></i></div>
          <h4><a>Revenue Cycle Management</a></h4>
          <p>Enhancing Financial Performance and Reducing Operational Costs in Service Delivery</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-heartbeat"></i></div>
          <h4><a>Hipaa Management</a></h4>
          <p>Ensure the integrity of protected health information with diligent administration of HIPAA regulations.</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4 mt-lg-0">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fa-solid fa-person-cane"></i></div>
          <h4><a>Patient Experience Management</a></h4>
          <p>Contemporary Patient Experience Enhancement: RDS Real-Time Feedback System for Ongoing Healthcare
            Experience Assessment</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-dna"></i></div>
          <h4><a>Healthcare Digital Marketing</a></h4>
          <p>Enhancing Service Profitability and Reducing Administrative Costs through Strategic Healthcare Digital
            Marketing</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-wheelchair"></i></div>
          <h4><a>Healthcare Analytics</a></h4>
          <p>Optimizing Service Profitability and Streamlining Administrative Efficiency through Advanced Healthcare
            Analytics</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-notes-medical"></i></div>
          <h4><a>Electronic Health Record</a></h4>
          <p>Enhancing Service Profitability and Administrative Efficiency through Electronic Health Records (EHR)
            Implementation</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-pills"></i></div>
          <h4><a>Practice Management</a></h4>
          <p>Optimize Administrative Efficiency and Appointment Scheduling with Cutting-Edge Front Office Software
            for Seamless Practice Operations</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fa-solid fa-user-nurse"></i></div>
          <h4><a>Business Intelligence</a></h4>
          <p>Utilize data analytics to unlock valuable business insights, empowering informed decision-making. Drive strategic success by harnessing the power of data-driven strategies.</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch mt-4">
        <div class="icon-box custom-card">
          <div class="icon"><i class="fas fa-hospital-user"></i></div>
          <h4><a>workforce Augmentation</a></h4>
          <p>Accelerate your expansion by leveraging the expertise of on-demand professionals and the efficiency of automation.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<div class="container">
<div class="row">
  <p class="second text-center" style="font-weight:bold; padding:1%;color:rgb(85, 83, 83);font-size:20px;">Empowering healthcare providers nationwide, we specialize in maximizing reimbursements for unpaid claims. 
  Below is a selection of some of the leading insurance companies we work with.
    </p>
</div><br><br>

<div class="row">
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(5).svg" width="162" height="54">
  </div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/monila.svg" style="height: 60px; object-fit: cover; width: 184px;">
  </div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(7).png" width="196" height="68">
  </div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(8).png" style="height: 67px; object-fit: cover; width: 229px;">
  </div>
</div>
<div class="row">
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(9).png" width="173" height="53">
  </div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(10).png" width="172"
      height="61"></div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(11).png" width="165"
      height="37"></div>
  <div class="col-md-3 mb-4" ><img class="d-block mx-auto logo" src="assets/img/eMDs.svg" style="height: 46px; object-fit: cover; width: 173px;"></div>
</div>

<div class="row">
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(1).svg" width="154" height="51">
  </div>
  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(2).webp" width="164" height="55">
  </div>

  <div class="col-md-3 mb-4"><img class="d-block mx-auto logo" src="assets/img/logo(4).png" width="191" height="65">
  </div>
</div>
</div>
<section class="bg-sec section-padding" style="background-color: #059FE7;">
<div class="container">
  <div class="row">
    <div class="col-md-7 d-flex align-items-center">
      <div>
        <h4 class="text-white text-darker mb-3"><b>We take immense pride in <br> our statistical achievements</b>
        </h4>
        <div class="d-flex align-items-center" style="height: 9vh;">    
          <p><img src="assets/img/bluetick.png" alt=""></p>
          <p class="text-white mx-3"> Efficient and Expedient Billing Processes: </p>
        </div>
        <div class="d-flex" style="height: 9vh;">
          <p><img src="assets/img/bluetick.png" alt=""></p>
          <p class="text-white mx-3"> Tailored Client-Centric Methodologies: </p>
        </div>
        <div class="d-flex" style="height: 9vh;">
          <p><img src="assets/img/bluetick.png" alt=""></p>
          <p class="text-white mx-3"> Delivering Tailored and Adaptive Strategies: </p>
        </div>
        <div class="d-flex" style="height: 9vh;">
          <p><img src="assets/img/bluetick.png" alt=""></p>
          <p class="text-white mx-3"> Robust Data Security Protocols: </p>
        </div>
        <div class="d-flex" style="height: 9vh;">
          <p><img src="assets/img/bluetick.png" alt=""></p>
          <p class="text-white mx-3"> Elevating Coding Accuracy and Workforce Efficiency: </p>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="card text-center million-card p-4">
            <p class="dolar">$1.2M</p>
            <p>Net patient revenue; Bad debt collected</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card text-center million-card p-4">
            <p class="dolar">$0.7M</p>
            <p>Net patient revenue has improved.</p>
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <div class="card text-center million-card p-4">
            <p class="dolar">$300k</p>
            <p>Bad debt collected from net patient revenue</p>
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <div class="card text-center million-card p-4">
            <p class="dolar">22%</p>
            <p>Reduction in the costs of collecting revenue. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<section id="testimonials" class="testimonials">
<div class="container">
  <div class="row">
    <h1 class="second text-center" style="font-weight:bold;">
      Our Clients
      <span class="second" style="color:#059FE7;">Love Us </span>
    </h1>
  </div><br>
  <div data-aos="fade-up" data-aos-delay="100"
    class="testimonials-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
    <div class="swiper-wrapper" id="swiper-wrapper-5cae4f83e3aafb28" aria-live="off"
      style="transform:translate3d(-2868px, 0px, 0px);transition-duration:600ms;">
      <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" role="group" aria-label="5 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>John Larson</h3>
            <h4>Entrepreneur</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Quis quorum aliqua sint quem legam fore sunt
              eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum
              fugiat legam esse veniam culpa fore nisi cillum quid. <i
                class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide" data-swiper-slide-index="0" role="group" aria-label="1 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Saul Goodman</h3>
            <h4>Ceo &amp; Founder</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Proin iaculis purus consequat sem cure digni
              ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh
              et. Maecen aliquam, risus at semper. <i class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="1" role="group" aria-label="2 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-2.jpg" class="testimonial-img" alt="">
            <h3>Sara Wilsson</h3>
            <h4>Designer</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Export tempor illum tamen malis malis eram quae
              irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster
              fugiat irure amet legam anim culpa. <i class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="2" role="group" aria-label="3 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-3.jpg" class="testimonial-img" alt="">
            <h3>Jena Karlis</h3>
            <h4>Store Owner</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Enim nisi quem export duis labore cillum quae
              magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram
              fore quis sint minim. <i class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide-next" data-swiper-slide-index="3" role="group" aria-label="4 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-4.jpg" class="testimonial-img" alt="">
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Fugiat enim eram quae cillum dolore dolor amet
              nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt
              elit fore quem dolore labore illum veniam. <i class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide" data-swiper-slide-index="4" role="group" aria-label="5 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>John Larson</h3>
            <h4>Entrepreneur</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Quis quorum aliqua sint quem legam fore sunt
              eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum
              fugiat legam esse veniam culpa fore nisi cillum quid. <i
                class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0" role="group" aria-label="1 / 5"
        style="width:936px;margin-right:20px;">
        <div class="testimonial-wrap">
          <div class="testimonial-item"><img src="assets/img/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Saul Goodman</h3>
            <h4>Ceo &amp; Founder</h4>
            <p><i class="bx bxs-quote-alt-left quote-icon-left"></i> Proin iaculis purus consequat sem cure digni
              ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh
              et. Maecen aliquam, risus at semper. <i class="bx bxs-quote-alt-right quote-icon-right"></i></p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
      <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span
        class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span
        class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button"
        aria-label="Go to slide 3" aria-current="true"></span><span class="swiper-pagination-bullet" tabindex="0"
        role="button" aria-label="Go to slide 4"></span><span class="swiper-pagination-bullet" tabindex="0"
        role="button" aria-label="Go to slide 5"></span></div><span class="swiper-notification"
      aria-live="assertive" aria-atomic="true"></span>
  </div>
</div>
</section>

`;


function HomePage() {
  console.log("HomePage called");
  const handleClick = (path) => {

    console.log(`Navigating to: ${path}`);

    window.location.href = path;
  };


  window.handleClick = handleClick;

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default HomePage;